'use client';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledListingBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '22rem',
  position: 'relative',
  borderRadius: '0.5rem',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.breakpoints.values.md,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '100%',
    height: '18rem',
  },
}));

export const TextOnImage = styled(Box)(({ theme }) => ({
  width: '70%',
  position: 'absolute',
  bottom: 10,
  left: 10,
}));
export const StyledPagination = styled(Pagination)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  margin: '1rem 0',
}));
