'use client';
import PaginationItem from '@mui/material/PaginationItem';
import BazaarCard from 'components/bazaarcard';
import { usePathname, useSearchParams } from 'next/navigation';
import { StyledPagination } from 'page/blog-listing/sections/style';
import { Suspense } from 'react';

interface Props {
  pagination: {
    page: number;
    totalPages: number;
  };
}

export const Pagination = ({ pagination }: Props) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const searchTerm = searchParams.get('searchTerm');

  return (
    <BazaarCard elevation={5}>
      <StyledPagination
        page={pagination?.page}
        count={pagination?.totalPages}
        color="primary"
        variant="text"
        renderItem={(item) => {
          const urlParams = new URLSearchParams({
            ...(searchTerm ? { searchTerm } : {}),
            page: item?.page?.toString() ?? '1',
          });
          return (
            <PaginationItem
              component="a"
              href={`${pathname}?${urlParams.toString()}`}
              {...item}
            />
          );
        }}
      />
    </BazaarCard>
  );
};

export const PaginationComponent = (props: Props) => {
  return (
    <Suspense>
      <Pagination {...props} />
    </Suspense>
  );
};
