import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/node_modules/@mui/material/Avatar/Avatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/node_modules/@mui/material/Grid/Grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["StyledCard","StyledImage","StyledTitleBox","StyledDescBox"] */ "/home/vsts/work/1/s/src/components/blog-card/style.ts");
;
import(/* webpackMode: "eager", webpackExports: ["PaginationComponent"] */ "/home/vsts/work/1/s/src/components/pagination/pagination.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/components/typography.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/page/home/sections/style.ts");
